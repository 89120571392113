import { IonAlert, IonCard, IonCardHeader } from "@ionic/react";

import {
    addCouponToBasket,
    fetchBasket,
    fetchOffers, setBasketLoading, setOfferAdding,
} from "../../redux/offer-list/offer-list.actions";
import {useHistory, useLocation} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import MembersService from "../../services/members.service";
import { setSelectedOffer } from "../../redux/offer-details/offer-details.actions";
import { connect } from "react-redux";
import { classNames } from "../header/header";
import cart from "./cartwhite.png";
import cartgreen from "./cartgreen.png"
import DataService from "../../services/data.service";
import GTMService from "../../services/gtm.service";
import { setEvent } from "../../redux/global/global.actions";

import './product-card.css'
import {useOnScreen} from "../../services/useOnScreen";
import {format} from "date-fns";
import {SET_OFFERS_ADDING} from "../../redux/offer-list/offer-list.types";
const cardStyle = {
  boxShadow: "rgb(0 0 0 / 10%) 0px 4px 16px",
};
interface Props {
  cartItemId?: number;
  state: any;
  offer: any;
  favoriteStores: any;
  homeScreenMode?: boolean;
  stores: any;
  added?: boolean;
  bestMode?: boolean;
  basketMode?: boolean;
  loading?: any;
  basket: any[];
  setAdded?: Function;
  addButtonDisabled?: boolean;
  setSelectedOffer: Function;
    setOfferAdding: Function;
  cards: any;
  cardsLoading: boolean;
  retailer: any;
  fetchOffers: Function;
  fetchBasket: Function;
  setBasketLoading: Function;
  setEvent: Function;
  addCouponToBasket: Function;
}

const mapStateToProps = (state: any) => {
  return {
    state: state,
    count: state.counter.count,
    favoriteStores: state.stores.favoriteStores,
    offerDetails: state.offerDetails,
    basket: state.offerList.basket,
    stores: state.stores,
    cards: state.cards.holders,
    cardsLoading: state.cards.loading,
    retailer: state.retailers.selected,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setSelectedOffer: (data: any) => dispatch(setSelectedOffer(data)),
    fetchOffers: () => dispatch(fetchOffers()),
    fetchBasket: () => dispatch(fetchBasket()),
    setEvent: (event: any) => dispatch(setEvent(event)),
    setBasketLoading: (loading: boolean) => dispatch(setBasketLoading(loading)),
    addCouponToBasket: (offerId: any, store: any) => dispatch(addCouponToBasket(offerId, store)),
      setOfferAdding: (loading: any) => dispatch(setOfferAdding(loading)),
  };
};

function useQueryParams() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const ProductCardComponent = (props: Props) => {
    // console.log( format(new Date(props.offer.ended_at), 'yyyy/MM/dd')+" まで")

    let queryParams = useQueryParams();
    let debug  = queryParams.get('debug');
    const { picture_url, title, subtitle, discount_value } = props.offer;
  const history = useHistory();
  const [openErrorModel, setOpenErrorModel] = useState<boolean>(false);
  const selectedStore = props.stores.selectedStore;
  const basketOfferIds = props.basket.map((b) => b.offer.id);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const isAddedToBasket = basketOfferIds.indexOf(props.offer.id) > -1;
    const ref = useRef(null);
    const componentView = useOnScreen(ref);
  let cardNumber = props.cards && props.cards.length > 0 ? props.cards[0].ref : 0
  // console.log(basketOfferIds, '************')
  const setSelectedOffer = () => {
    return MembersService.getCouponDetail(props.offer.id);
  };
  let src = props.bestMode ? cartgreen : cart;
  return (
      <div
          className={classNames(
              props.bestMode
                  ? "border-okuwa_blue border-2"
                  : "border-gray-300",
              "m-0 flex flex-col bg-white rounded-md shadow-md mt-2 border  "
          )}
          style={cardStyle}
          ref={ref}
          data-id={props.offer.id}
      >
          {/*<div className="product">*/}
              {/*<div className="product-image"></div>*/}

              {/*<span className="price"> &gt; {props.offer.user_quota - props.offer.quota_used}</span>*/}

              {/*<span className="price"> &gt; {props.offer.user_quota}</span>*/}

              {/*<p>Doodle BronzeCast</p>*/}
          {/*</div>*/}

          <div className="p-1 h-full">
              <IonCardHeader
                  className={"flex-1 "}
                  onClick={async () => {
                      if (
                        //   !selectedStore ||
                        //   selectedStore === null ||
                        //   selectedStore.id === null ||
                        //   selectedStore.id === "undefined" ||
                          (props.cards.length === 0 && props.cardsLoading === false)
                      ) {
                          return false;
                      }
                      const offer = await setSelectedOffer();
                      props.setSelectedOffer({
                          ...offer.data,
                          isAddedInBasket: props.added,
                      });
                      // GTMService.triggerGTM({
                      //   eventName: 'product_view',
                      //   product: offer.data,
                      //   data: { ...props.state },
                      // })
                      history.push("/product-details/"+offer.data.id);
                  }}
              >
                  {props.bestMode && <div className="speech bottom">
                      {/*特別クーポン*/}
                      お客様限定
                  </div>}
                  {/*{props.bestMode && <div className="speech2 bottom">*/}
                  {/*    /!*特別クーポン*!/*/}
                  {/*    店舗限定*/}
                  {/*</div>}*/}
                  <img src={picture_url}/>
                  <div className="text-sm text-center mt-3 dark:text-black">{title}</div>
                  <div className="text-sm text-center mt-3 dark:text-black">{subtitle}</div>

              </IonCardHeader>
          </div>
          <div
              className="text-sm text-center mt-3 dark:text-black p-2 m-1 rounded-md border-2">{format(new Date(props.offer.ended_at), 'yyyy/MM/dd') + " まで"}</div>
          <div
              className={classNames(
                  "pl-2 pr-2.5 py-3 border-t border-gray-300",
                  props.bestMode
                      ? "bg-okuwa_blue text-white"
                      : "bg-white rounded-b-2xl"
                  // isAddedToBasket
                  //   ? "bg-gray-100"
                  //   : "bg-okuwa_blue"
              )}

          >
              <div className="flex items-center">
                  <div
                      className={classNames("flex-1")
                          // isAddedToBasket
                          //   ? "text-black"
                          //   : "text-white"
                      }
                  >
                      <div className={classNames("text-lg font-bold", props.bestMode
                          ? "dark:text-white" : "dark:text-black")}>{discount_value} P</div>
                      <div className={classNames("text-xs", props.bestMode?"dark:text-white":"dark:text-black")} >
                          {isAddedToBasket && 'バスケットに追加済'}
                          {!isAddedToBasket && 'バスケットに追加'}
                      </div>
                  </div>
                  <div
                      className={classNames(
                          "flex w-8 h-8 rounded-full items-center justify-center",
                          props.bestMode
                              ? "bg-white" : "bg-okuwa_blue"
                      )}>
                      <img src={src} className="w-5 h-5"
                           onClick={async () => {
                               if (
                                //    !selectedStore ||
                                //    selectedStore === null ||
                                //    selectedStore.id === null ||
                                //    selectedStore.id === "undefined" ||
                                   (props.cards.length === 0 && props.cardsLoading === false)
                               ) {
                                   setErrorMessage("お店を登録してください");
                                   setOpenErrorModel(true);
                                   return false;
                               }
                               if (isAddedToBasket) {
                                   return false;
                               }
                               props.setBasketLoading(false)
                               props.setEvent("Clipped");
                               props.setSelectedOffer(props.offer)
                               props.setOfferAdding(true);
                               MembersService.addCouponToBasket(props.offer.id, null)

                                   .then((responseJson) => {
                                       props.setOfferAdding(false);
                                       props.fetchOffers();
                                       props.fetchBasket();
                                   })
                                   .catch((error) => {
                                       props.setOfferAdding(false);
                                       setErrorMessage(error.response.data.error)
                                       setOpenErrorModel(true);
                                   });
                           }}/>
                  </div>
              </div>
              {openErrorModel && errorMessage && (
                  <IonAlert
                      mode="ios"
                      isOpen={openErrorModel}
                      onDidDismiss={() => {
                          setOpenErrorModel(false);
                      }}
                      cssClass="confirm-modal"
                      message={`<p class="text-base dark:text-white font-semibold">${errorMessage}</p>`}
                      buttons={[
                          {
                              text: "閉じる",
                              cssClass: "py-6",
                              handler: () => {
                                  return true;
                              },
                          },
                      ]}
                  />
              )}
          </div>
      </div>
  );
};
export const ProductCard = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductCardComponent);
