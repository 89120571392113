import { IonContent, IonPage, IonLoading } from "@ionic/react";
import "./home.css";
import {useEffect, useRef, useState} from "react";

import { SegmentFilters } from "../../components/segment-filters/segment-filters";

import { ProductCard } from "../product-card/product-card";
import { Steps } from "../../components/steps/steps";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  fetchBasket,
  fetchOffers, setBasketLoading,
  setOfferList, setOfferListLoading,
} from "../../redux/offer-list/offer-list.actions";
import { fetchCategories } from "../../redux/categories/categories.actions";

import {removeEvent, setAppLoading, setEvent, setFirstLoad} from "../../redux/global/global.actions";
import sadface from "./sadface.png";
import { fetchRetailers } from "../../redux/retailers/retailers.actions";
import { fetchHolderCards } from "../../redux/holder-cards/holder-cards.actions";

import { Loader } from "./loader";
import GTMService from "../../services/gtm.service";
import {classNames} from "../header/header";

const mapStateToProps = (state: any) => {
  return {
    state: state,
    offers: state.offerList.offers,
    bestOffers: state.offerList.bestOffers,
    nonBestOffers: state.offerList.nonBestOffers,
    basket: state.offerList.basket,
    allBasket: state.offerList.allBasket,
    offersLoading: state.offerList.offersLoading,
    basketReLoaded: state.offerList.basketReLoaded,
    isAddingOffer: state.offerList.isAddingOffer,
    event: state.global.event,
    selectedStore: state.stores.selectedStore,
    selectedCategory: state.categories.selectedCategory,
    categoriesLoaded: state.categories.categoriesLoaded,
    isAppLoading: state.global.isAppLoading,
    first_load: state.global.first_load,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setOfferList: (data: any) => dispatch(setOfferList(data)),
    fetchRetailers: () => dispatch(fetchRetailers()),
    fetchOffers: () => dispatch(fetchOffers()),
    fetchHolderCards: () => dispatch(fetchHolderCards()),
    fetchBasket: () => dispatch(fetchBasket()),
    fetchCategories: () => dispatch(fetchCategories()),
    setAppLoading: (data: any) => dispatch(setAppLoading(data)),
    setEvent: (data: any) => dispatch(setEvent(data)),
    setBasketLoading: (data: boolean) => dispatch(setBasketLoading(data)),
    setOfferListLoading: (data: any) => dispatch(setOfferListLoading(data)),
    removeEvent: () => dispatch(removeEvent()),
    setFirstLoad:(data:any) => dispatch(setFirstLoad(data))
  };
};
interface Props {
  selectedCategory: any;
  selectedStore: any;
  state: any;
  isAddingOffer: boolean;
  offers: any[];
  bestOffers: any[];
  nonBestOffers: any[];
  basket: any[];
  allBasket: any[];
  categoriesLoaded: boolean;
  offersLoading: boolean;
  basketReLoaded: boolean;
  isAppLoading: boolean;
  first_load: boolean;
  event: any;
  setEvent: Function;
  setBasketLoading: Function;
  removeEvent: Function;
  setOfferList: Function;
  fetchOffers: Function;
  fetchCategories: Function;
  fetchHolderCards: Function;
  fetchRetailers: Function;
  fetchBasket: Function;
  setOfferListLoading: Function;
  setFirstLoad: Function;
}

export const HomeComponent = (props: Props) => {
  const [categoriesLoaded, setCategoriesLoaded] = useState<boolean>(false);
  const [storesLoaded, setStoresLoaded] = useState<boolean>(false);
  const [showBanner, setShowBanner] = useState(false);
  const [basketIds, setBasketIds] = useState<any>([]);

  const stepsref = useRef<null | HTMLDivElement>(null);
  let [onScroll, setOnScroll] = useState(true);
  let [scrollEvents, setScrollEvents] = useState(!!onScroll);

  const { t } = useTranslation();
  useEffect(() => {
    console.log("LOADING")
    if (!props.isAppLoading) {
      // console.log('Loading home page ----')
      GTMService.triggerGTM({
        eventName: 'home',
        data: props.state,
      })
    }
  }, [props.isAppLoading])
  useEffect(() => {
    let date = new Date();
    if (date > new Date("Aug 01 2024 00:00:00") && date < new Date("Aug 07 2024 23:59:59")) { setShowBanner(true) }
    // setBasketItemsCount();
    props.fetchRetailers();
    props.fetchHolderCards();
    props.fetchCategories();
    setCategoriesLoaded(true);
  }, []);

  useEffect(() => {
    if (props.categoriesLoaded) {
      props.setOfferListLoading(true);
      props.fetchOffers();
      localStorage.removeItem('from-terms')
      props.fetchBasket();
    }
  }, [
    props.categoriesLoaded,
    props.selectedCategory,
    props.selectedStore,
  ]);

  useEffect(() => {
    if (props.event) {
      if (props.event == 'ClippedFromDetails' && props.basketReLoaded) {
        // console.log('DONE');
        GTMService.triggerGTM({
          eventName: 'ClippedFromDetails',
          data: props.state,
        })
        props.setEvent(null);
        props.setBasketLoading(false)
      }

      if (props.event == 'Clipped' && props.basketReLoaded) {
        // console.log('DONE');
        GTMService.triggerGTM({
          eventName: 'Clipped',
          data: props.state,
        })
        props.setEvent(null);
        props.setBasketLoading(false)
      }
    }

  }, [props.event, props.basketReLoaded])
  useEffect(()=>{
    let basketOfferIds = props.allBasket.map((b) => b.offer.id);
    if(basketOfferIds && basketOfferIds.length>0){
      setBasketIds(basketOfferIds)
    }else {
      setBasketIds([])
    }
  },[props.basket, props.offers])

  useEffect(() => {
    if (props.event) {
      if (props.event == 'CATEGORY_CHANGED' && !props.offersLoading) {
        GTMService.triggerGTM({
          eventName: 'CATEGORY_CHANGED',
          data: { ...props.state, changedCategory: props.selectedCategory },
        })
        props.setEvent(null);
      }
    }

    if (props.event == 'store_changed' && !props.offersLoading) {
      GTMService.triggerGTM({
        eventName: 'store_changed',
        data: props.state,
      })
      props.setEvent(null);
    }

  }, [props.offersLoading])

let offers = props.offers.filter((offer)=>{
  return basketIds.indexOf(offer.id)<0
})

  let bestOffers = props.bestOffers.filter((offer)=>{
    return basketIds.indexOf(offer.id)<0
  })

  let nonBestOffers = props.nonBestOffers.filter((offer)=>{
    return basketIds.indexOf(offer.id)<0
  })
  const scrollToLast = () => {
    stepsref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <IonPage className="pt-14">
      <IonContent className="bg-white"  scrollEvents={scrollEvents} onIonScroll={()=>{
        console.log('scrolling')
        setOnScroll(false)
        setScrollEvents(false);
        if(props.first_load){
          props.setFirstLoad(false);
        }
      }}>
        {props.isAppLoading && (
          <div className="flex justify-center pt-24">
            <div className="loader">
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </div>
          </div>
        )}
        {!props.isAppLoading && showBanner &&
          <div className="mt-6 pt-1 text-sm border border-gray-900 m-1.5 font-black"
            style={{ "borderRadius": "1rem" }}>
            <div className="m-4 text-sm text-gray-900">
              ＜クータッチ システムアップデートのお知らせ＞<br/><br/>
              これまでクーポンの有効期限はバスケットに追加してから14日間でしたが、クーポン掲載期間を有効期限とする
              システムアップデートを行いました。<br/>
              これにより、クーポンをバスケットに追加した場合、14日間で消えることなくバスケットに残ります。
              クーポンをご利用になった場合は、バスケットから消えますが、複数回利用可能なクーポンに限り、ご利用後すぐにクーポン一覧に戻ります。
              再度ご利用の場合は、バスケットに追加してご利用ください。
              <br/>
              ※一部クーポンについて、バスケットに追加してから14日間の有効期限を設ける場合がございます。その場合有効期限を過ぎますと
              バスケットから外され、7日後にクーポン一覧に再表示されます。
            </div>
          </div>
        }
        {!props.isAppLoading && (
          <>
            <IonLoading
              cssClass="my-custom-class"
              isOpen={props.isAddingOffer}
              // onDidDismiss={() => console.log("Hi")}
              message={"お待ちください..."}
              duration={5000}
            />
            {/*<div className="p-4 dark:bg-white dark:text-black">*/}
            {/*  <div className="flex">*/}
            {/*    /!*<div className="text-lg flex-1 text-left">{t("find_a_coupon")}</div>*!/*/}
            {/*    <div className="text-lg flex-1 text-right" onClick={scrollToLast}>クーポンの使い方</div>*/}

            {/*  </div>*/}
            {/*</div>*/}
            <SegmentFilters />
            <div className="p-4 bg-okuwa_bg">
              {/* <div className="mt-4 text-lg font-bold text-gray-600">
                {t("coupon_not_found")}
              </div>

              <div className="mt-2 text-sm text-gray-500 ">
                {t("coupon_near_favorite_store")}
              </div> */}
              {/* <div className="flex items-center justify-between mt-4 mb-2"> */}
              {/* <div>{t(props.selectedStore ? props.selectedStore.name:"selected store")}</div> */}
              {/*<div className="flex items-center text-okuwa_blue">*/}
              {/*  {t("save_as_bookmark")}*/}
              {/*  <PlusCircleIcon className="w-8 h-8 ml-2" />*/}
              {/*</div>*/}
              {/* </div> */}
              <div className="grid grid-cols-2 gap-4 pt-3 bg-okuwa_bg">
                {props.offersLoading && <>
                  <Loader></Loader>
                  <Loader></Loader>
                  <Loader></Loader>
                  <Loader></Loader>
                </>}
              </div>
                {!props.offersLoading && bestOffers && bestOffers.length > 0 &&
                    <div className="dark:text-black">
                      <div className="mx-2 font-bold text-sm flex items-center rounded-md">
                        {/*<StarIcon className="h-5 w-5 mr-1" />*/}
                        あなたへの特別クーポン

                      </div>
                      <div className="grid grid-cols-2 gap-4 pt-3 bg-okuwa_bg" >
                        {bestOffers.length > 0 && bestOffers.map((offer, index) => (
                            <ProductCard key={offer.id} offer={offer} bestMode={true}/>
                        ))}
                      </div>
                    </div>



                }
              {!props.offersLoading && nonBestOffers && nonBestOffers.length > 0 &&
                  <div className={
                    classNames("dark:text-black",!props.offersLoading && bestOffers && bestOffers.length > 0 ? "pt-6" : "-mt-3")
                  }>
                    {!props.offersLoading && bestOffers && bestOffers.length > 0 && <div className="mx-2 my-2 font-bold text-sm flex items-center rounded-md">
                      {/*<StarIcon className="h-5 w-5 mr-1" />*/}
                      お得なクーポン
                    </div>
                    }
                    <div className="grid grid-cols-2 gap-4 pt-3 bg-okuwa_bg" >
                      {nonBestOffers.length > 0 && nonBestOffers.map((offer, index) => (
                          <ProductCard key={offer.id} offer={offer} />
                      ))}
                    </div>
                  </div>
              }

              {props.offers.length == 0 && (
                <div className="dark:text-black">
                  <div className="flex justify-center mt-10">
                    <img
                      className="flex-none w-36 rounded-md object-center object-cover"
                      src={sadface}
                    />
                  </div>
                  <div className="p-4 mt-6 m-4 rounded-md flex flex-col items-center">
                    <div className="pb-1 text-gray-500">すみません、</div>
                    <div className="text-gray-500 pb-4">
                      クーポンが見つかりませんでした。
                    </div>
                  </div>
                  <div className="text-lg text-gray-600 font-bold">
                    別カテゴリーを選ぶか、他のお気に入り店舗をリストから選んでください
                  </div>
                </div>
              )}
            </div>
            <Steps stepsref={stepsref} />
          </>
        )}
      </IonContent>
      {/* <IonButton
        className="m-0"
        color="primary"
        expand="full"
        routerLink="/cart"
      >
        カートに移動
      </IonButton> */}
    </IonPage>
  );
};

export const Home = connect(mapStateToProps, mapDispatchToProps)(HomeComponent);
