import { IonPage, useIonViewDidEnter, useIonViewDidLeave } from "@ionic/react";
import { useEffect, useState } from "react";
import { Header } from "../header/header";
import { connect } from "react-redux";
import { format, formatDistance, isBefore, parseISO, setHours, setMinutes, setSeconds } from 'date-fns'
import { ja, enGB } from 'date-fns/locale'
import {
  fetchCategories,
} from "../../redux/categories/categories.actions";
import {
  addCouponToBasket,
  fetchBasket, fetchOffers,
  setBasketLoading,
  setOfferAdding
} from "../../redux/offer-list/offer-list.actions";
import { InformModal } from "../../components/inform-modal/inform-modal";
import DataService from "../../services/data.service";
import { useHistory, useLocation } from "react-router-dom";
import { LocationMarkerIcon } from "@heroicons/react/outline";
import GTMService from "../../services/gtm.service";
import {setAppLoading, setEvent} from "../../redux/global/global.actions";
import MembersService from "../../services/members.service";
import {useParams} from "react-router";
import {setSelectedOffer} from "../../redux/offer-details/offer-details.actions";
import {SET_OFFERS_ADDING} from "../../redux/offer-list/offer-list.types";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const cardStyle = {
  boxShadow: "0px -1px 20px 4px #00000021",
};

const mapStateToProps = (state: any) => {
  return {
    state: state,
    offerDetails: state.offerDetails.selectedOffer,
    basket: state.offerList.basket,
    stores: state.stores,
    favoriteStores: state.stores.favoriteStores,
    cards: state.cards.holders,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchCategories: () => dispatch(fetchCategories()),
    setOfferAdding: (loading: boolean) => dispatch(setOfferAdding(loading)),
    setSelectedOffer: (data: any) => dispatch(setSelectedOffer(data)),
    setEvent: (event: any) => dispatch(setEvent(event)),
    setBasketLoading: (loading: boolean) => dispatch(setBasketLoading(loading)),
    setAppLoading: (data: any) => dispatch(setAppLoading(data)),
    fetchBasket: () => dispatch(fetchBasket()),
    fetchOffers: () => dispatch(fetchOffers()),
    addCouponToBasket: (offerId: any, store: any) =>
      dispatch(addCouponToBasket(offerId, store)),

  };
};
interface Props {
  state: any;
  offerDetails: any;
  basket: any[];
  cards: any;
  favoriteStores: any;
  stores: any;
  fetchCategories: Function;
  addCouponToBasket: Function;
  setSelectedOffer: Function;
  setBasketLoading: Function;
  setAppLoading: Function;
  setOfferAdding:Function;
  setEvent: Function;
  fetchBasket: Function;
  fetchOffers: Function;
}

export const ProductDetails = (props: Props) => {
  const history = useHistory();
  const location = useLocation();
  const basketOfferIds = props.basket.map((b) => b.offer.id);
  const selectedStore = props.stores.selectedStore;
  const isAddedToBasket = basketOfferIds.indexOf(props.offerDetails.id) > -1;
  const [tabs, setTabs] = useState([
    { name: "ポイント", href: "#", current: true },
    { name: "バーコード", href: "#", current: false },
  ]);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [openErrorModel, setOpenErrorModel] = useState<boolean>(false);
  const offer = props.offerDetails;
  let overviewText = offer && offer?.overview ? offer?.overview.split('\n').map((item: any, i: any) => {
    if (item) {
      return <p key={i} className="my-1">{item}</p>
    }
  }) : []
  let disclaimerText = offer && offer?.disclaimer ? offer?.disclaimer.split('\n').map((item: any, i: any) => {
    if (item) {
      return <p key={i} className="my-1">{item}</p>
    }
  }): []
  const  data:any  = useParams()
  // console.log(data.id)
  let setDistance = (expired_at: any) => {
    let expiry_time = new Date(expired_at);
    expiry_time = setHours(expiry_time, 23);
    expiry_time = setMinutes(expiry_time, 59)
    expiry_time = setSeconds(expiry_time, 59);
    console.log(expiry_time)

    console.log(isBefore(expiry_time, new Date()))


    console.log(expiry_time)

    if(isBefore(expiry_time, new Date())){
      return "有効期限終了"
    } else {
      return "有効期限：あと" + formatDistance(expiry_time, new Date(), { locale: ja })
    }
  }

  let cardNumber = props.cards && props.cards.length > 0 ? props.cards[0].ref : 0
  useEffect(() => {
    console.log(data.id)
    if(!props.offerDetails.id){
      MembersService.getCouponDetail(data.id).then((result)=>{
        console.log(result.data)
        props.setSelectedOffer(result.data)
        props.setAppLoading(false);
      });
      // props.setSelectedOffer({
      //   ...offer.data,
      //   isAddedInBasket: props.added,
      // });
    }
    // console.log('Landing on the product details page')
    // GTMService.triggerGTM({
    //   eventName: 'product_view',
    //   product: offer,
    //   data: { ...props.state },
    // })
  }, [props.offerDetails])

  useIonViewDidLeave(() => {
    setTimeout(() => {
      GTMService.triggerGTM({
        eventName: 'NAVIGATE',
        url: location.pathname,
        data: { ...props.state },
      })
    }, 1000)
  }, []);
  useIonViewDidEnter(() => {
    // console.log('Landing on the product details page')
    GTMService.triggerGTM({
      eventName: 'product_view',
      product: offer,
      data: { ...props.state },
    })
  }, [])
  if(!offer.id){
    return (
      <></>
    )
  } else {
    return (<IonPage className="overflow-auto">
      <Header />
      <div className="flex flex-1 flex-col overflow-auto">
        <div className="top bg-white relative px-6">
          <div className="pt-10 pb-14 flex justify-center relative">
            <div className="bg-gray-100 px-4 py-1 rounded-md absolute top-6 left-0 dark:text-black " onClick={() => {
              history.goBack();
              // GTMService.triggerGTM({
              //   eventName: 'NAVIGATE',
              //   url: window.location.pathname,
              //   data: {...props.state},
              // })
            }}>
              戻る
            </div>
            <img className="h-56 mt-6" src={offer?.picture_url} />
          </div>
        </div>
        <div className="bg-gray-100 relative">
          <div
              className="list flex-1 mx-6 mb-6 p-6 bg-white -mt-10 rounded-md"
              style={{ ...cardStyle }}
          >
            <div className="mt-2 text-center dark:bg-white dark:text-black">
              <div className="text-base">{offer?.title}</div>
              <div className="text-gray-700 mt-3">{offer?.subtitle}</div>
              {/* <div className="flex justify-center mt-6">
                <div
                  className=" px-4 py-1 rounded-md"
                  style={{ background: "#EDEDED" }}
                >
                  {offer.categories &&
                    offer.categories.length &&
                    offer.categories[0].name}
                </div>
              </div> */}

              <div
                  className="flex flex-col bg-white p-2 m-2 mt-2 rounded-md border-2"
              >
                <div className="flex flex-col justify-center items-center">
                  <div className="text-sm dark:text-black">
                    掲載期間：{format(new Date(offer.started_at), 'yyyy/MM/dd')} ~ {format(new Date(offer.ended_at), 'yyyy/MM/dd')}
                  </div>

                </div>
                {/*<div className="text-lg mt-4">有効期限： {offer?.expiredAt}</div>*/}
              </div>

              <div
                  className="flex flex-col bg-white p-6 m-3 mt-3 rounded-md border-2 border-green-500"
                  style={{ borderColor: "#009260" }}
              >
                <div className="flex flex-col justify-center items-center">
                  <div className="text-5xl font-bold dark:text-black">
                    {offer ? offer.discount_value : "NA. "}P
                  </div>
                  {isAddedToBasket && (
                      <div className="pl-1 mt-4">{props.basket.find(x => x.offer.id === offer.id) ? setDistance(props.basket.find(x => x.offer.id === offer.id).expired_at) : "hello"} </div>
                  )
                  }
                  {/* <div className="pl-1 mt-4">ポイント</div> */}
                </div>
                {/*<div className="text-lg mt-4">有効期限： {offer?.expiredAt}</div>*/}
              </div>
              <div className="flex px-3 flex-col bg-white mt-3 rounded-md text-sm text-left leading-5">
                {
                  overviewText.map((item: any) => {
                    return item
                  })
                }
              </div>
              <div className="h-4"></div>
              {/* <div className="flex flex-col bg-white py-2 mt-4 rounded-md"> */}
              {/* <div className="flex items-center"> */}
              {/* <div className="left-icon w-12">
                   <LocationMarkerIcon className="text-okuwa_blue" />
                  </div> */}
              {/* <div className="px-3 text-sm text-left leading-5">
                    {
                      disclaimerText.map((item:any)=>{
                        return item
                      })
                    }
                  </div> */}
              {/* </div> */}
              {/* </div> */}
              <div className="flex px-3 flex-col text-gray-600 text-sm bg-white rounded-md text-left leading-5">
                {/*to change*/}
                {/*※クーポンはバスケットに追加してから14日以内にご利用ください。*/}
                {/*14日を経過しますとバスケットから外され、数日後、クーポン一覧に再表示されます。*/}
                {/*ご利用のためには再度バスケットに追加することが必要ですのでご注意ください。*/}
                ※クーポンをご利用の際は、お会計時にオーカード・オーカードプラスをご提示ください。
                <br/><br/>
                登録（タッチ）したクーポン有効期限の確認方法<br/>
                ①画面上部のバスケット（画像）を選択<br/>
                ②登録済みのクーポン画面下部に有効期限が表示<br/>
                ※商品によって有効期限が異なりますので登録（タッチ）後 は有効期限をお確かめの上ご利用願います。<br/>

                <br/><br/>
                {/*※バスケットに追加されたクーポンは、クーポン一覧から一時的に表示が消えますが、*/}
                {/*クーポンご利用後すぐにクーポン一覧に表示されます。再度ご利用の場合は、バスケットに追加してご利用ください。*/}
                ※クーポンによっては利用可能回数に制限がある場合がございます。
                <br/><br/>

                {/*※クーポンによっては再使用可能回数に*/}
                {/*制限がある場合がございます。*/}
                ※バスケットに追加されたクーポンは、クーポン一覧から表示が消えますが、
                複数回利用可能なクーポンに限り、ご利用後クーポン一覧に表示されます。
                再度ご利用の場合は、バスケットに追加してご利用ください。<br/><br/>
                ※一部クーポンは、クーポン利用日翌日までバスケットに残る場合がございます。
                バスケットから自動でクーポンが消えるまでお待ちくださいませ。

                {/*一行開あけて、次の文（※店舗によりクーポン対象～）をはじめる*/}
                {/* <br/><br/> */}
                {/* ※有効期限が14日間のクーポンは、バスケットに追加してから14日以内にご利用ください。14日を経過しますとバスケットから外され、数日後、クーポン一覧に再表示されます。ご利用のためには再度バスケットに追加することが必要ですのでご注意ください。 */}

                {/*to change*/}
                <br/><br/>
                ※店舗によりクーポン対象商品が取り扱われていない場合がございます。
                <br/><br/>
                ※クーポンは予告なく変更、終了になる場合がございます。
                <br/><br/>
                以上ご了承ください。
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className="bottom-nav">
        <div className="flex">
          <div className="py-4 px-5 text-xl font-bold flex-1 dark:bg-white dark:text-black border-t-1 border-gray-600">
            {offer ? offer.discount_value : "NA. "}P
          </div>
          <div
              className={classNames(
                  "py-4 px-5 text",
                  isAddedToBasket
                      ? "text-black bg-gray-100"
                      : "text-white bg-okuwa_blue"
              )}
              onClick={async () => {
                // if (
                //     !selectedStore || selectedStore === null ||
                //     selectedStore.id === null ||
                //     selectedStore.id === "undefined"
                // ) {
                //   // console.log("Please select a store");
                //   setErrorMessage("お店を選択してください");
                //   setOpenErrorModel(true);
                //   return false;
                // }
                if (!isAddedToBasket) {
                  // GTMService.triggerGTM({
                  //   eventName: 'ClippedFromDetails',
                  //   data: props.state,
                  // })


                  // props.addCouponToBasket(offer.id, selectedStore);

                }
                props.setOfferAdding(true);
                MembersService.addCouponToBasket(offer.id, selectedStore)
                    .then((response) => {
                      // eventBus.dispatch("refetchBasket", {});
                      props.setEvent("ClippedFromDetails");
                      props.setOfferAdding(false);
                      props.fetchOffers();
                      props.fetchBasket();
                      history.push("/home");
                    })
                    .catch((error) => {
                      props.setOfferAdding(false);
                      if (error.response && error.response.data) {
                        setErrorMessage(error.response.data.error);
                        setOpenErrorModel(true);
                      }
                    });
              }}
          >
            {isAddedToBasket && <span>バスケットに追加済</span>}
            {!isAddedToBasket && <span>バスケットに追加</span>}

          </div>
        </div>
      </div>
      {openErrorModel && (
          <InformModal
              message={errorMessage}
              open={openErrorModel}
              setOpen={setOpenErrorModel}
          />
      )}
    </IonPage>)
  }
};
export const ProductDetailsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductDetails);
