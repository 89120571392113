import {
  IonContent,
  IonHeader,
  IonPage,
  IonCard,
  IonCardHeader,
  IonTitle,
  IonToolbar,
  IonButton,
  useIonViewDidEnter,
  useIonViewWillEnter,
} from "@ionic/react";
import "./Cart.css";

import sadface from "./sadface.png";
import { useEffect, useState } from "react";
import { StoreSelector } from "../store-selector/store-selector";
import { CartCard } from "../../components/cart-card/cart-card";
import { connect } from "react-redux";

import {
  fetchBasket,
  fetchOffers,
} from "../../redux/offer-list/offer-list.actions";
import { Steps } from "../../components/steps/steps";
import { setSelectedOffer } from "../../redux/offer-details/offer-details.actions";
import { useHistory } from "react-router";
import GTMService from "../../services/gtm.service";

const mapStateToProps = (state: any) => {
  return {
    state: state,
    offers: state.offerList.offers,
    event: state.global.event,
    basket: state.offerList.basket,
    basketReLoaded: state.offerList.basketReLoaded,
    selectedStore: state.stores.selectedStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setSelectedOffer: (data: any) => dispatch(setSelectedOffer(data)),
    fetchBasket: () => dispatch(fetchBasket()),
    fetchOffers: () => dispatch(fetchOffers()),
  };
};
interface Props {
  state: any;
  event: any;
  offers: any[];
  basket: any[];
  fetchBasket: Function;
  basketReLoaded: boolean;
  setSelectedOffer: Function;
  selectedStore: any;
  fetchOffers: Function;
}

export const CartComponent = (props: Props) => {
  const history = useHistory();
  useEffect(() => {
    if (props.event !== null && props.event !== 'CATEGORY_CHANGED' && props.basketReLoaded) {
      props.fetchOffers();
      props.fetchBasket();
    }
    if (props.event == 'removedFromCart' && props.basketReLoaded) {
      GTMService.triggerGTM({
        eventName: 'REMOVED',
        url: "/cart",
        data: { ...props.state },
      })
    }
  }, [props.basketReLoaded]);
  return (
    <IonPage className="pt-14">
      <IonContent className="bg-white">
        {/*<div className="p-4 pb-10 bg-white">*/}
        {/*  /!*<div className="text-lg">クーポンを探そう</div>*!/*/}
        {/*  <StoreSelector />*/}
        {/*</div>*/}

        {props.basket.length !== 0 && (
          <div className="bg-white p-4 dark:text-black">
            <div className="grid grid-cols-1 gap-2 pt-3 bg-white">
              {props.basket.map((item) => (
                <CartCard
                  cart={item}
                  fetchBasket={props.fetchBasket}
                  fetchOffers={props.fetchOffers}
                  key={item.id}
                  setSelectedOffer={props.setSelectedOffer}
                />
              ))}
            </div>
          </div>
        )}

        {props.basket.length == 0 && (
          <div className="rounded-md pt-12 flex flex-col items-center bg-okuwa_bg dark:text-black">
            <img className="flex h-24" src={sadface} />
            <div className="text-center text-lg px-6 pt-4">すみません、</div>
            <div className="text-center text-lg px-6 pb-4">
              クーポンが見つかりませんでした
            </div>
            <button
              onClick={() => {
                history.push("/home")
                GTMService.triggerGTM({
                  eventName: 'NAVIGATE',
                  url: "/home",
                  data: { ...props.state },
                })
              }}
              type="button"
              className="mb-8 mt-4 inline-flex items-center px-10 py-4 text-lg border border-transparent font-medium rounded-md shadow-sm text-white bg-okuwa_blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-okuwa_blue"
            >
              クーポンを追加
            </button>
            <Steps />
          </div>
        )}
      </IonContent>
      {/* <IonButton
        className="m-0"
        color="primary"
        expand="full"
        routerLink="/home"
      >
        クーポンを追加
      </IonButton> */}
    </IonPage>
  );
};

export const Cart = connect(mapStateToProps, mapDispatchToProps)(CartComponent);
